\<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            label="공정 또는 작업장명"
            name="processName"
            v-model="searchParam.processName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            label="실내외구분"
            name="inOutFlag"
            v-model="searchParam.inOutFlag">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            label="발생원"
            name="sourceName"
            v-model="searchParam.sourceName">
          </c-text>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-process
            label="관련공정"
            multiple="single"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept
            type="search"
            label="관리부서"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="localven"
          title="국소배기장치 목록"
          tableId="localven"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          rowKey="psiLocalVentilationId"
          :columnSetting="false"
          :expandAll="true"
          @linkClick="linkClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn
                v-if="editable"
                label="엑셀업로드"
                icon="upload"
                @btnClicked="excelUploadData" />
                  <c-btn v-if="editable" label="등록" icon="add" @btnClicked="equipmentPopup" />
                  <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'local-ven',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: 'Rev.',
            align: 'center',
            style: 'width:50px',
            sortable: true,
          },
          {
            name: 'processName',
            field: 'processName',
            label: '공정또는작업장명',
            style: 'width:150px',
            align: 'left',
            type: 'link',
            sortable: true,
          },
          {
            name: 'inOutFlag',
            field: 'inOutFlag',
            label: '실내외구분',
            align: 'center',
            style: 'width:60px',
            sortable: true,
          },
          {
            name: 'sourceName',
            field: 'sourceName',
            label: '발생원',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'harmfulTypeCd',
            field: 'harmfulTypeCd',
            label: '유해물질종류',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'hoodForm',
            field: 'hoodForm',
            label: '후드형식',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'hoodVelocity',
            field: 'hoodVelocity',
            label: '후드의제어풍속(m/s)',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'reactionVelocity',
            field: 'reactionVelocity',
            label: '덕트내반응속도(m/s)',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'airVolume',
            field: 'airVolume',
            label: '배풍량(㎥/min)',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'motorVolume',
            field: 'motorVolume',
            label: '전동기용량(kW)',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'motorExplosionForm',
            field: 'motorExplosionForm',
            label: '전동기방폭형식',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'airCleanType',
            field: 'airCleanType',
            label: '공기정화장치형식',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'airExhaustOrder',
            field: 'airExhaustOrder',
            label: '배기 및 처리순서',
            align: 'left',
            style: 'width:160px',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: '',
        processName: '',
        inOutFlag: '',
        sourceName: '',
        processCd: '',
        deptCd: '',
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.psi.lbe.localven.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.equipmentPopup(row);
    },
    equipmentPopup(result) {
      this.popupOptions.target = () => import(`${"./localExhaustSystemPlanInfoDetail.vue"}`);
      this.popupOptions.title = '국소배기장치 상세'; 
      this.popupOptions.param = {
        psiLocalVentilationId: result ? result.psiLocalVentilationId : '',
      };
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    excelUploadData() {
      this.popupOptions.title = '국소배기장치 목록 업로드';
      this.popupOptions.target = () => import(`${'./localExhaustSystemPlanInfoExcelUpload.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeExcelUploadPopup;
    },
    closeExcelUploadPopup(_result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (_result && _result.length > 0) {
        let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
        this.$_.forEach(s_data, item => {
          // item.plantCd = this.searchParam.plantCd;
          item.SysRevision = 1;
          item.regUserId = this.$store.getters.user.userId;  // 등록자 ID
          item.chgUserId = this.$store.getters.user.userId;  // 수정자 ID
        })

        this.$http.url = transactionConfig.psi.lbe.localven.insert.url + '/excel';
        this.$http.type = 'POST';
        this.$http.param = s_data;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        });
      }
    },
  }
};
</script>
